.shariff {
    .diaspora {
        a {
            background-color: #999;
            &:hover {
                background-color: #b3b3b3;
            }
        }
        .fa-times-circle {
            font-size: 17px;
        }
    }
    .theme-white {
        .diaspora a {
            color: #999;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .diaspora .fa-times-circle {
            font-size: 16px;
        }
    }
}
