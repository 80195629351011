.shariff {
    .xing {
        a {
            background-color: #126567;
            &:hover {
                background-color: #29888a;
            }
        }
        .fa-xing {
            font-size: 22px;
        }
        .share_count {
            color: #15686a;
            background-color: #4fa5a7;
        }
    }
    .theme-white {
        .xing a {
            color: #126567;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .xing .fa-xing {
            font-size: 19px;
        }
    }
}
