.shariff {
    .telegram {
        a {
            background-color: #0088cc;
            &:hover {
                background-color: #007dbb;
            }
        }
        .fa-telegram {
            font-size: 28px;
        }
    }
    .theme-white {
        .telegram a {
            color: #0088cc;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .telegram .fa-telegram {
            font-size: 22px;
        }
    }
}
