.shariff {
    .flipboard {
        a {
            background-color: #e12828;
            &:hover {
                background-color: #ff2e2e;
            }
        }
        .fa-flipboard {
            font-size: 22px;
        }
    }
    .theme-white {
        .flipboard a {
            color: #e12828;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .flipboard .fa-flipboard {
            font-size: 19px;
        }
    }
}
