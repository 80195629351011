.shariff {
    .weibo {
        a {
            background-color: #F56770;
            &:hover {
                background-color: #FA7F8A;
            }
        }
        .fa-weibo {
            font-size: 28px;
        }
        .share_count {
            color: #0174a4;
            background-color: #F56770;
        }
    }
    .theme-white {
        .weibo a {
            color: #F56770;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .weibo .fa-weibo {
            font-size: 24px;
        }
    }
}
