.shariff {
    .tencent-weibo {
        a {
            background-color: #26ACE0;
            &:hover {
                background-color: #38BBEB;
            }
        }
        .fa-tencent-weibo {
            font-size: 28px;
        }
        .share_count {
            color: #0174a4;
            background-color: #26ACE0;
        }
    }
    .theme-white {
        .tencent-weibo a {
            color: #26ACE0;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .tencent-weibo .fa-tencent-weibo {
            font-size: 24px;
        }
    }
}
