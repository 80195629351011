.shariff {
    .facebook {
        a {
            background-color: #3b5998;
            &:hover {
                background-color: #4273c8;
            }
        }
        .fa-facebook-f {
            font-size: 22px;
        }
        .share_count {
            color: #183a75;
            background-color: #99adcf;
        }
    }
    .theme-white {
        .facebook a {
            color: #3b5998;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .facebook .fa-facebook-f {
            font-size: 19px;
        }
    }
}
