.shariff {
    .stumbleupon {
        a {
            background-color: #eb4924;
            &:hover {
                background-color: #ef7053;
            }
        }
        .fa-stumbleupon {
            font-size: 17px;
        }
    }
    .theme-white {
        .stumbleupon a {
            color: #eb4924;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .stumbleupon .fa-stumbleupon {
            font-size: 16px;
        }
    }
}
