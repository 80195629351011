.shariff {
  .threema {
    a {
      background-color: #333333;
      &:hover {
        background-color: #1f1f1f;
      }
    }
    .fa-lock {
      font-size: 28px;
    }
  }
  .theme-white {
    .threema a {
      color: #333333;
    }
  }
}

@media only screen and (min-width: 600px) {
  .shariff {
    .threema .fa-lock {
      font-size: 22px;
    }
  }
}
