.shariff{

    ul{
        list-style:none!Important;
        display:flex;

        li .share_text, li .share_count{
            font-family:inherit!important;
            font-weight:600;
        }
    }
    &.orientation-horizontal {
        flex-wrap: wrap;
        align-items: flex-start;
        text-align: center;
        li{
            height:25px;
        }
    }
    &.orientation-vertical {
        min-width: 110px;
        li {
            display: block;
            width: 100%;
            margin: 5px 0;
        }
    }
}
