.shariff {
    .print {
        a {
            background-color: #999;
            &:hover {
                background-color: #a8a8a8;
            }
        }
        .fa-print {
            font-size: 21px;
        }
    }
    .theme-white {
        .print a {
            color: #999;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .print .fa-print {
            font-size: 18px;
        }
    }
}
