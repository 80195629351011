.shariff {
  .tumblr {
    a {
      background-color: #36465D;
      &:hover {
        background-color: #44546B;
      }
    }
    .fa-tumblr {
      font-size: 28px;
    }
  }
  .theme-white {
    .tumblr a {
      color: #5cbe4a;
    }
  }
}

@media only screen and (min-width: 600px) {
  .shariff {
    .tumblr .fa-tumblr {
      font-size: 22px;
    }
  }
}

