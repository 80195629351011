.shariff {
    .linkedin {
        a {
            background-color: #0077b5;
            &:hover {
                background-color: #0369a0;
            }
        }
        .fa-linkedin-in {
            font-size: 22px;
        }
        .share_count {
            color: #004785;
            background-color: #33AAE8;
        }
    }
    .theme-white {
        .linkedin a {
            color: #0077b5;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .linkedin .fa-linkedin-in {
            font-size: 19px;
        }
    }
}
