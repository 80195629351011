.shariff {
    .reddit {
        a {
            background-color: #ff4500;
            &:hover {
                background-color: #ff6a33;
            }
        }
        .fa-reddit {
            font-size: 17px;
        }
    }
    .theme-white {
        .reddit a {
            color: #ff4500;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .reddit .fa-reddit {
            font-size: 16px;
        }
    }
}
