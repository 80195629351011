.shariff {
    .twitter {
        a {
            background-color: #55acee;
            &:hover {
                background-color: #32bbf5;
            }
        }
        .fa-twitter {
            font-size: 28px;
        }
        .share_count {
            color: #0174a4;
            background-color: #96D4EE;
        }
    }
    .theme-white {
        .twitter a {
            color: #55acee;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .twitter .fa-twitter {
            font-size: 24px;
        }
    }
}
