.shariff {
    .addthis {
        a {
            background-color: #f8694d;
            &:hover {
                background-color: #f75b44;
            }
        }
        .fa-plus {
            font-size: 14px;
        }
        .share_count {
            color: #f8694d;
            background-color: #f1b8b0;
        }
    }
    .theme-white {
        .addthis a {
            color: #f8694d;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .addthis .fa-plus {
            font-size: 14px;
            position: relative;
            top: 1px;
        }
    }
}

