.display-share-text() {
    li {
        min-width: 110px;
        max-width: 160px;
        .share_text {
            text-indent: 0;
            display: inline;
        }
    }
}

.shariff {
    &::after,
    &::before {
        content: " ";
        display: table;
    }
    &::after {
        clear: both;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    li {
        height: 35px;
        box-sizing: border-box;
        overflow: hidden;
        a {
            color: #fff;
            position: relative;
            display: block;
            height: 35px;
            text-decoration: none;
            box-sizing: border-box;
        }
        .share_text,
        .share_count {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 12px;
            vertical-align: middle;
            line-height: 35px;
        }
        .fab,
        .far,
        .fas {
            width: 35px;
            line-height: 35px;
            text-align: center;
            vertical-align: middle;
        }
        .share_count {
            padding: 0 8px;
            height: 33px;
            position: absolute;
            top: 1px;
            right: 1px;
        }
    }

    // ------------- horizontal alignment (standard)
    .orientation-horizontal {
        /* autoprefixer: off */
        display: -webkit-box;
        li {
            -webkit-box-flex: 1;
        }
        .info {
            -webkit-box-flex: 0;
        }
    }
    .orientation-horizontal {
        display: flex;
        flex-wrap: wrap;
        li {
            float: left;
            flex: none;
            width: 35px;
            margin-right: 3%;
            margin-bottom: 10px;
            &:last-child {
                margin-right: 0;
            }
            .share_text {
                display: block;
                text-indent: -9999px;
                padding-left: 3px;
            }
            .share_count {
                display: none;
            }
        }
    }

    // ------------------------- colourless design

    .theme-grey {
        .shariff-button {
            a {
                background-color: #b0b0b0;
            }
            .share_count {
                background-color: #ccc;
                color: #333;
            }
        }
    }

    // ------------------------- even less colorful design

    .theme-white {
        .shariff-button {
            border: 1px solid #ddd;
            a {
                background-color: #fff;
                &:hover {
                    background-color: #eee;
                }
            }
            .share_count {
                background-color: #fff;
                color: #999;
            }
        }
    }

    // ------------------------- vertical alignment

    .orientation-vertical {
        &.button-style-icon {
            min-width: 35px;
        }
        &.button-style-icon-count {
            min-width: 80px;
        }
        &.button-style-standard {
            min-width: 110px;
        }
        li {
            display: block;
            width: 100%;
            margin: 5px 0;
        }
        &.button-style-standard, &.button-style-icon-count {
            li {
                .share_count {
                    width: 24px;
                    text-align: right;
                }
            }
        }
    }
}

// ---------------  adjustments for small devices

@media only screen and (min-width: 360px) {
    .shariff .orientation-horizontal {
        li {
            margin-right: 1.8%;
        }
        &.button-style-standard, &.button-style-icon-count {
            li {
                min-width: 80px;
                .share_count {
                    display: block;
                }
            }
        }
        &.button-style-standard {
            li {
                width: auto;
                flex: 1 0 auto;
            }
        }
        &.button-style-standard.shariff-col-1, &.button-style-standard.shariff-col-2 {
            .display-share-text;
        }
        &.button-style-standard.shariff-col-5, &.button-style-standard.shariff-col-6 {
            li {
                flex: none;
            }
        }
    }
}

@media only screen and (min-width: 640px) {
    .shariff .orientation-horizontal.button-style-standard.shariff-col-3 {
        .display-share-text;
    }
}

@media only screen and (min-width: 768px) {
    .shariff .orientation-horizontal.button-style-standard {
        .display-share-text;
        &.shariff-col-5, &.shariff-col-6 {
            li {
                flex: 1 0 auto;
            }
        }
    }
}

// --------------- desktop layout

@media only screen and (min-width: 1024px) {
    .shariff {
        li {
            height: 30px;
            a {
                height: 30px;
            }
            .fab,
            .far,
            .fas{
                width: 30px;
                line-height: 30px;
            }
            .share_text,
            .share_count {
                line-height: 30px;
            }
            .share_count {
                height: 28px;
            }
        }
    }
}
