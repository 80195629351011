.shariff {
    .googleplus {
        a {
            background-color: #d34836;
            &:hover {
                background-color: #f75b44;
            }
        }
        .fa-google-plus-g {
            font-size: 22px;
        }
        .share_count {
            color: #a31601;
            background-color: #eda79d;
        }
    }
    .theme-white {
        .googleplus a {
            color: #d34836;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .googleplus .fa-google-plus-g {
            font-size: 19px;
        }
    }
}
