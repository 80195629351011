.shariff {
    .qzone {
        a {
            background-color: #2B82D9;
            &:hover {
                background-color: #398FE6;
            }
        }
        .fa-qq {
            font-size: 28px;
        }
        .share_count {
            color: #0174a4;
            background-color: #2B82D9;
        }
    }
    .theme-white {
        .qzone a {
            color: #2B82D9;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .qzone .fa-qq {
            font-size: 24px;
        }
    }
}
