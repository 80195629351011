.shariff {
    .flattr {
        a {
            background-color: #7ea352;
            &:hover {
                background-color: #F67C1A;
                .share_count {
                    color: darken(#F67C1A, 10%);
                    background-color: lighten(#F67C1A, 20%);
                }
            }
        }
        .fa-money-bill-alt {
            font-size: 22px;
        }
        .share_count {
            color: darken(#7ea352, 10%);
            background-color: lighten(#7ea352, 20%);
        }
    }
    .theme-white {
        .flattr a {
            color: #F67C1A;
        }
    }
}

@media only screen and (min-width: 600px) {
    .shariff {
        .flattr .fa-money-bill-alt {
            font-size: 19px;
        }
    }
}
